<template>
	<div class="app-body has-bottom">
		<div class="home-container scroll-cont">
			<div class="header-box">
				<img src="../assets/images/1.png" alt="">
			</div>
			<div class="home-item" @click="goPath('/RepairList')" v-if="getShow([1,2,4])">
				<div class="home-icon">
					<img src="../assets/images/icon1.png" alt="">
				</div>
				<div class="home-cont">
					<p class="f16">报修单管理</p>
					<p class="text-grey mts">快速核销设备</p>
				</div>
				<div class="home-arrow">
					<img src="../assets/images/arrow.png" alt="">
				</div>
			</div>
			<div class="home-item" @click="goPath('/DeviceTestList')" v-if="getShow([1,2,4])">
				<div class="home-icon">
					<img src="../assets/images/icon2.png" alt="">
				</div>
				<div class="home-cont">
					<p class="f16">设备检测</p>
					<p class="text-grey mts">检测退回设备</p>
				</div>
				<div class="home-arrow">
					<img src="../assets/images/arrow.png" alt="">
				</div>
			</div>
		</div>
		<foot-guide></foot-guide>
	</div>
</template>
<script>
import footGuide from '@/components/footer/index'
import { Popup } from 'vant'
export default{
	name:'Home',
	components: {
		footGuide,
		[Popup.name]:Popup
	},
	data() {
		return {
			users: {'role':1},
			show: false,
			days: 0
		}
	},
	mounted() {

	},
	filter: {
		
	},
	methods: {
		goPath(path) {
			this.$router.push({path:path})
		},
		getShow(value){
			for(let i=0;i<value.length;i++){
				if(value[i]===this.users.role){
					return true;
				}
			}
			return false;
		}
	}
}
</script>
<style lang="scss" scoped>
	.app-body{
		position:relative;
	}
	.home-item{
		background:#fff;
		padding:0.55rem 0;
		border-radius:0.3rem;
		box-shadow:0 0 0.3rem rgba(153,153,153,0.1);
		margin:0.26rem 0.4rem;
		display:flex;
		justify-content:space-between;
		align-items:center;
		.home-icon{
			width:2rem;
			padding:0 0.5rem;
			border-right:1px solid #dbdbdb;
		}
		.home-cont{
			flex:1;
			padding-left:0.5rem;
		}
		.home-arrow{
			width:1.06rem;
			padding:0 0.4rem;
		}
	}
	.header-box{
		overflow-x:hidden;
		position:relative;
		margin:0.4rem 0.4rem 0rem;
	}
</style>