<template>
	<section id='foot_guide' v-show='prop'>
        <section @click = "gotoAddress({path: '/Home'})" class="guide_item">
            <span class="icon_style" :class="$route.meta.requireBelong.indexOf('Home') !== -1? 'homeActive' : 'home'"></span>
            <span :class="$route.meta.requireBelong.indexOf('Home') !== -1? 'active' : ''">生产工具</span>
        </section>
        <section @click = "gotoAddress({path: '/User'})" class="guide_item">
            <span class="icon_style" :class="$route.meta.requireBelong.indexOf('User') !== -1? 'userActive' : 'user'"></span>
            <span :class="$route.meta.requireBelong.indexOf('User') !== -1? 'active' : ''">个人中心</span>
        </section>
    </section>
</template>
<script>
export default{
    name:'footGuide',
	components: {

	},
	data() {
		return {
           prop:true 
		}
	},
	watch: {

	},
	computed: {
  
    },
	created() {

	},
	mounted() {
	},
	methods: {
        gotoAddress(path) {
           this.$router.push(path) 
        }
	}
}
</script>
<style lang="scss" scoped>
    @import '../../assets/css/mixin';
    #foot_guide{
        background-color: #fff;
        position: fixed;
        z-index: 100;
        left: 0;
        right: 0;
        bottom: 0;
        @include wh(100%, 1.45rem);
        display: flex;
        box-shadow: 0 -1 0.1rem rgba(0,0,0,.1);
        border-top:1px solid #f7f7f7;
    }
    .guide_item{
        flex: 1;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        .icon_style{
            @include wh(0.6rem, 0.6rem);
            margin-top: .18rem;
            fill: #ccc;

        }
        .home{
            @include bis('../../assets/images/home.png')
        }
        .homeActive{
            @include bis('../../assets/images/homeActive.png')
        }
        .user{
            @include bis('../../assets/images/user.png')
        }
        .userActive{
            @include bis('../../assets/images/userActive.png')
        }
        span{
            margin-top:0.05rem;
            @include sc(0.32rem, #999);
            &.active{
                color:#54c1cf;
            }
        }
    }
</style>